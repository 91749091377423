@import-normalize;

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

html,
body,
#root {
  height: 100%;
}
